<template>
  <main>
    <trac-back-button class="pb-4">
      <span class="text-gray-600"> Back </span>
    </trac-back-button>
    <h1 class="text-gray-800 font-semibold capitalize text-sm">
      {{ $route.params.type }}
    </h1>
    <div
      class="shadow pt-10 pb-40 border w-full rounded-lg mt-8 flex flex-col items-center justify-center"
    >
      <div class="w-6/12 flex flex-col items-center justify-center">
        <img class="w-16 h-16" :src="iconSwitch.icon" alt="" />
        <p class="text-base font-semibold mt-3 text-primaryBlue leading-7">
          Add {{ iconSwitch.text }} Details
        </p>
        <p class="text-xs text-center mt-2">
          Please fill the details below to pay for your
          {{ $route.params.type }}
          <span v-if="$route.params.type === 'Electricity'"> Bill </span>
        </p>

        <div class="w-full">
          <trac-dropdown-exteneded
            @optionSelected="selectOption($event)"
            placement="left"
            :options="services"
            :neededProperty="'ServiceID'"
            class="my-6"
            :selector="
              iconSwitch.text === 'Cable Tv' ||
              iconSwitch.text === 'Internet' ||
              iconSwitch.text === 'Airtime'
                ? 'Select Package'
                : 'Electricity Provider'
            "
          >
          </trac-dropdown-exteneded>

          <trac-input
            type="number"
            class="mt-5"
            :placeholder="dynamicSecond"
            v-model="cableTv.smartCardNumber"
          ></trac-input>

          <trac-dropdown-exteneded
            v-if="iconSwitch.text === 'Electricity'"
            @optionSelected="selectOptionTwo($event)"
            placement="left"
            :options="[
              {
                name: 'prepaid',
              },
              {
                name: 'postpaid',
              },
            ]"
            :neededProperty="'name'"
            class="my-6"
            selector="Select type"
          >
          </trac-dropdown-exteneded>

          <trac-button
            :disabled="(loading === true) || (allowedToProceed === false && numberProvidedIsNumeric === false)"
            @click.native="verify"
            class="w-full mt-5"
          >
            <span class="uppercase"> Continue </span>
          </trac-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import cableTv from "@/assets/svg/cable-tv.svg"
import internet from "@/assets/svg/internet.svg"
import utilities from "@/assets/svg/utilities.svg"
import cableImages from "@/assets/svg/cable-images.svg"
import internetImages from "@/assets/svg/internet-images.svg"
import utilitiesImages from "@/assets/svg/utilities-images.svg"
import airtime from "@/assets/svg/buy_airtime.svg"
import { eventBus } from "../../eventBus"

export default {
  data() {
    return {
      options: [],
      amount: 5000,
      confirmPayment: false,
      services: [],
      cableTv: {
        smartCardNumber: "",
        package: "",
      },
      loading: false,
      type: "",
    }
  },
  computed: {
    numberProvidedIsNumeric() {
      return !isNaN(this.cableTv.smartCardNumber) && this.cableTv.smartCardNumber !== ""
    },
    allowedToProceed () {
      if (this.iconSwitch.text === "Cable Tv" || this.iconSwitch.text === "Internet") {
        return (this.cableTv.package && this.cableTv.smartCardNumber) || false
      } 
      else {
        return (this.cableTv.package && this.cableTv.smartCardNumber && this.type) || false
      }
    },
    dynamicSecond() {
      if (this.iconSwitch.text === "Cable Tv") {
        return "Smart Card Number"
      } else if (this.iconSwitch.text === "Electricity") {
        return "Meter Number"
      } else if (this.iconSwitch.text === "Internet") {
        return "Customer ID"
      } else if (this.iconSwitch.text === "Airtime") {
        return "Phone Number"
      }
    },
    iconSwitch() {
      switch (this.$route.params.type) {
        case "Cable Tv":
          return {
            icon: cableTv,
            text: "Cable Tv",
            image: cableImages,
          }
        case "Internet Services":
          return {
            icon: internet,
            text: "Internet",
            image: internetImages,
          }
        case "Electricity":
          return {
            icon: utilities,
            text: "Electricity",
            image: utilitiesImages,
          }
        case "Airtime":
          return {
            icon: airtime,
            text: "Airtime",
            image: utilitiesImages,
          }
      }
    },
  },
  methods: {
    async verify() {
      if (this.cableTv.package === "") {
        eventBus.$emit("trac-alert", {
          message: "Please select a package",
        })
        return
      }

      if (this.iconSwitch.text === "Internet") {
        if (this.cableTv.smartCardNumber === "") {
          eventBus.$emit("trac-alert", {
            message: "Please enter Customer Id",
          })
          return
        }
        this.$router.push({
          name: "BillPaymentTwo",
          params: {
            type: this.iconSwitch.text,
            smartCardNumber: this.cableTv.smartCardNumber,
            package: this.cableTv.package,
            name: "N/A",
          },
        })
        return
      }

      this.loading = true

      const response = await this.$store.dispatch("VERIFY_DEVICE", {
        serviceID: this.cableTv.package,
        smartCardNumber: this.cableTv.smartCardNumber,
        type: this.type,
      })

      if (response.status) {
        const options = {
          type: this.iconSwitch.text,
          smartCardNumber: this.cableTv.smartCardNumber,
          package: this.cableTv.package,
          name: response.data.Customer_Name || "N/A",
        }

        if (this.iconSwitch.text === "Electricity") {
          options.code = this.type
        }
        this.$router.push({
          name: "BillPaymentTwo",
          params: options,
        })
      } else {
        eventBus.$emit("trac-alert", {
          message: response.message,
        })
      }
      this.loading = false
    },
    selectOption(value) {
      this.cableTv.package = value.ServiceID
    },
    selectOptionTwo(value) {
      this.type = value.name
    },

    async fetchProviders() {
      try {
        const response = await this.$store.dispatch("FETCH_PROVIDERS")
        return response?.data?.Categories
      } catch (error) {
        console.log(error)
      }
    },
  },
  async mounted() {
    const res = await this.fetchProviders()
    if (this.iconSwitch.text === "Cable Tv") {
      this.services = res.CableTV.filter((data) => data.ServiceID !== "showmax")
    } else if (this.iconSwitch.text === "Electricity") {
      this.services = res.Electricity
    } else if (this.iconSwitch.text === "Internet") {
      this.services = res.Internet
    } else if (this.iconSwitch.text === "Airtime") {
      this.services = res.Airtime
    }
  },
}
</script>

<style></style>
