var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('trac-back-button',{staticClass:"pb-4"},[_c('span',{staticClass:"text-gray-600"},[_vm._v(" Back ")])]),_c('h1',{staticClass:"text-gray-800 font-semibold capitalize text-sm"},[_vm._v(" "+_vm._s(_vm.$route.params.type)+" ")]),_c('div',{staticClass:"shadow pt-10 pb-40 border w-full rounded-lg mt-8 flex flex-col items-center justify-center"},[_c('div',{staticClass:"w-6/12 flex flex-col items-center justify-center"},[_c('img',{staticClass:"w-16 h-16",attrs:{"src":_vm.iconSwitch.icon,"alt":""}}),_c('p',{staticClass:"text-base font-semibold mt-3 text-primaryBlue leading-7"},[_vm._v(" Add "+_vm._s(_vm.iconSwitch.text)+" Details ")]),_c('p',{staticClass:"text-xs text-center mt-2"},[_vm._v(" Please fill the details below to pay for your "+_vm._s(_vm.$route.params.type)+" "),(_vm.$route.params.type === 'Electricity')?_c('span',[_vm._v(" Bill ")]):_vm._e()]),_c('div',{staticClass:"w-full"},[_c('trac-dropdown-exteneded',{staticClass:"my-6",attrs:{"placement":"left","options":_vm.services,"neededProperty":'ServiceID',"selector":_vm.iconSwitch.text === 'Cable Tv' ||
            _vm.iconSwitch.text === 'Internet' ||
            _vm.iconSwitch.text === 'Airtime'
              ? 'Select Package'
              : 'Electricity Provider'},on:{"optionSelected":function($event){return _vm.selectOption($event)}}}),_c('trac-input',{staticClass:"mt-5",attrs:{"type":"number","placeholder":_vm.dynamicSecond},model:{value:(_vm.cableTv.smartCardNumber),callback:function ($$v) {_vm.$set(_vm.cableTv, "smartCardNumber", $$v)},expression:"cableTv.smartCardNumber"}}),(_vm.iconSwitch.text === 'Electricity')?_c('trac-dropdown-exteneded',{staticClass:"my-6",attrs:{"placement":"left","options":[
            {
              name: 'prepaid',
            },
            {
              name: 'postpaid',
            },
          ],"neededProperty":'name',"selector":"Select type"},on:{"optionSelected":function($event){return _vm.selectOptionTwo($event)}}}):_vm._e(),_c('trac-button',{staticClass:"w-full mt-5",attrs:{"disabled":(_vm.loading === true) || (_vm.allowedToProceed === false && _vm.numberProvidedIsNumeric === false)},nativeOn:{"click":function($event){return _vm.verify.apply(null, arguments)}}},[_c('span',{staticClass:"uppercase"},[_vm._v(" Continue ")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }